import { reduce, isNil } from 'lodash';

export const toHashParams = (args: Record<string, any>) => {
  const combinedValues = reduce(
    args,
    (acc: string[], v: any, k: string) => {
      if (isNil(v)) {
        return acc;
      }
      return [...acc, `${k}=${v}`];
    },
    []
  ).join('&');
  return `#${combinedValues}`;
};
