import React, { useEffect, useState } from 'react';
import { Field, Form, Formik } from 'formik';
import {
  ExternalLink,
  FooterTeaser,
  FormGroup,
  Header,
  Section,
  SiteLogo,
  SiteName,
  SubHeader,
  Svg,
  TextboxField,
  TradingBlockLink,
  useTracking,
  Loading,
  ContactPhone,
  ContactEmail,
} from '@tradingblock/components';
import { GetUrlParams, UserRequirementsUrl } from '@tradingblock/api';
import { ConfigType, VirtualTradingPaths, config as Config } from '../clientConfig';
import { TradingBlockEnvironments, UserRequirementsInfo, SecurityQuestionType } from '@tradingblock/types';
import * as Yup from 'yup';
import { Link, useParams } from 'react-router-dom';
import * as H from 'history';
import fetch from 'cross-fetch';

const getEnvironment = (location: H.Location<any>) => {
  const params = GetUrlParams(location.search);
  const environment = params['environment'] || undefined;
  if (environment) {
    return environment;
  } else if (
    Config.environment === TradingBlockEnvironments.virtual ||
    VirtualTradingPaths.includes(location.pathname)
  ) {
    return 'virtual';
  } else {
    return 'dashboard';
  }
};

const getConfig = (env: any): ConfigType => {
  if (env === 'virtual') {
    return Config.virtual;
  }
  return Config.dashboard;
};

interface GetSecurityChallengeFormValues {
  userName: string;
}

interface ResetPasswordConfirmationFormValues {
  email: string | undefined;
  password: string;
  passwordConfirm: string;
  securityAnswer: string;
}

interface PasswordChangeRequest {
  changeToken: string;
  userName: string;
}

export default function ResetPasswordConfirmation({ location }: { location: any }) {
  const urlParams = GetUrlParams(location.search);
  const env = getEnvironment(location);
  const isVirtual = env === 'virtual';

  const grp = Config.siteGrp;
  const siteParam = grp ? `?grp=${grp}` : '';
  let group = grp === 'mb' ? 'mb' : 'tb';
  const config = getConfig(env);
  const apiRootUrl = config.authurl || undefined;

  const [resetError, setResetError] = useState<string>();
  const { token } = useParams();
  const [successfulSubmission, setSuccessfulSubmission] = useState<boolean>(false);
  const [currentSecurityQuestion, setCurrentSecurityQuestion] = useState<SecurityQuestionType | undefined>(undefined);
  const [authError, setAuthError] = useState<string>();
  const [isSecurityQuestionFetching, setIsSecurityQuestionFetching] = useState<boolean>(false);
  const [passwordFinalizeRequest, setPasswordFinalizeRequest] = useState<PasswordChangeRequest>({
    changeToken: token,
    userName: '',
  });
  const [submissionProcessing, setSubmissionProcessing] = useState<boolean>(false);
  const [contactUs, setContactUs] = useState<boolean>(false);

  //TODO: Need to validate if token is valid and if not, redirect to home page or error page.
  // Not entirely sure what URL to be fetching against to validate this token. Only available urls from the config appear to be RestHub.
  // console.log(token);

  // Format security questions based on the security question type
  const formatSecurityQuestion = (question: SecurityQuestionType) => {
    switch (question) {
      case SecurityQuestionType.BirthCity:
        return 'What is the city of your birth? (e.g. London)';
      case SecurityQuestionType.ChildhoodFutureJobWish:
        return 'What is your future job wish? (e.g. CEO)';
      case SecurityQuestionType.ChildhoodPetName:
        return 'What is your pet name? (e.g. Fluffy)';
      case SecurityQuestionType.MothersMaidenName:
        return 'What is your mothers maiden name? (e.g. Smith)';
      case SecurityQuestionType.FavoriteSportsTeam:
        return 'What is your favorite sports team? (e.g. Juventus)';
      case SecurityQuestionType.PaternalGrandmothersFirstName:
        return 'What is your paternal grandmothers first name? (e.g. Mary)';
      case SecurityQuestionType.FavoriteBook:
        return 'What is your favorite book? (e.g. The Hobbit)';
      case SecurityQuestionType.DriversLicenseLast4:
        return 'What is the last 4 digits of your drivers license? (e.g. 1234)';
      case SecurityQuestionType.FathersMiddleName:
        return 'What is your fathers middle name? (e.g. John)';
      default:
        return '';
    }
  };

  // Validation for security challenge request form
  const onValidate = (values: GetSecurityChallengeFormValues) => {
    if (!values.userName) {
      return { [`userName`]: 'Username is required.' };
    }
    return {};
  };

  // On submit for security challenge request form
  const onSubmitRequestSecurityChallenge = (values: GetSecurityChallengeFormValues) => {
    if (currentSecurityQuestion === undefined) {
      setIsSecurityQuestionFetching(true);
      fetch(`${apiRootUrl}/User/GetSecurityChallenge?username=${values.userName}&temptoken=${token}`, {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      })
        .then(async response => {
          if (response.status === 200) {
            const content = await response.json();
            if (content.ResponseCode === 0) {
              setAuthError('');
              setIsSecurityQuestionFetching(false);
              setPasswordFinalizeRequest({
                ...passwordFinalizeRequest,
                changeToken: token,
                userName: values.userName,
              });
              setCurrentSecurityQuestion(content.Payload);
              if (content.Payload.length === 0) {
                setContactUs(true);
                setAuthError(
                  'No security questions found. To continue with the password reset process, please contact support.'
                );
              }
            } else if (content.ResponseCode === 209) {
              setIsSecurityQuestionFetching(false);
              setAuthError('Invalid security question answer.');
            } else if (content.ResponseCode === 99) {
              setIsSecurityQuestionFetching(false);
              setAuthError('Username not found or token has expired.');
            } else {
              setIsSecurityQuestionFetching(false);
              setAuthError(`Error(${content.ResponseCode}): ${content.Payload ? content.Payload[0] : ''}`);
            }
          }
        })
        .catch(error => {
          setIsSecurityQuestionFetching(false);
          setAuthError(error.toString());
        });
    }
  };

  // On submit for password change finalize form
  const onSubmit = async (values: ResetPasswordConfirmationFormValues) => {
    setSubmissionProcessing(true);
    const request = {
      changeToken: token,
      email: values.email,
      passwordSecret: values.password,
      securityChallenge: {
        answer: values.securityAnswer,
        securityQuestionType: currentSecurityQuestion,
      },
      userName: passwordFinalizeRequest.userName,
    };
    fetch(`${apiRootUrl}/User/PasswordChangeFinalize?siteid=${group}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(request),
    })
      .then(async response => {
        if (response.status === 200) {
          setAuthError(undefined);
          setResetError(undefined);
          const content = await response.json();
          setSubmissionProcessing(false);
          if (content.ResponseCode === 0) {
            setSuccessfulSubmission(true);
            setSubmissionProcessing(false);
          } else {
            setResetError(`Error(${content.ResponseCode}): ${content.Payload ? content.Payload[0] : ''}`);
            setSubmissionProcessing(false);
          }
        }
      })
      .catch(error => {
        setAuthError(error.toString());
        setSubmissionProcessing(false);
      });
  };

  const href = location.pathname + location.search;
  return (
    <div className={isVirtual ? 'env-virtual' : ''}>
      <Header>
        <SubHeader>
          <SiteLogo siteGroup={group} />
          <nav className="nav-utilities">
            <ul>
              <li>
                {isVirtual ? (
                  <ExternalLink href={Config.goToLiveUrl + siteParam}>Go to live trading account</ExternalLink>
                ) : (
                  <TradingBlockLink to="LegacySiteHomeUrl" siteGroup={group} origin={group}>
                    &uarr; Go to <SiteName siteGroup={group} /> Legacy
                  </TradingBlockLink>
                )}
              </li>
              <li>
                <TradingBlockLink to="CustomerServiceUrl" siteGroup={group} origin={group}>
                  Contact us
                </TradingBlockLink>
              </li>
            </ul>
          </nav>
        </SubHeader>
      </Header>
      <main className="login">
        <Section>
          <div className="content">
            <div className="login-container">
              <div className="login-primary">
                <h1>
                  <i aria-hidden="true" className={`fal ${isVirtual ? 'fa-vr-cardboard' : 'fa-lock-alt'}`} />{' '}
                  {isVirtual ? 'Virtual Forgot Password' : 'Forgot Password'}
                </h1>
                {isVirtual && (
                  <>
                    <p>
                      Welcome to Virtual Trading. If you do not have a new Virtual account,{' '}
                      <ExternalLink href={Config.applicationUrl + siteParam}>
                        create a virtual trading account
                      </ExternalLink>
                    </p>
                    <p>NOTE: Your live account login is not compatible with Virtual Trading.</p>
                  </>
                )}
                {(isSecurityQuestionFetching || submissionProcessing) && <Loading />}
                {currentSecurityQuestion === undefined && !submissionProcessing && (
                  <Formik
                    initialValues={{
                      userName: '',
                    }}
                    onSubmit={onSubmitRequestSecurityChallenge}
                    validate={onValidate}
                  >
                    {({ errors, touched, values, submitCount, handleSubmit }) => (
                      <form onSubmit={handleSubmit} autoComplete="new-password">
                        <FormGroup>
                          <Field component={TextboxField} id="userName" placeholder="Username" autoFocus={true} />
                        </FormGroup>
                        <button
                          className={`btn btn-block btn-light btn-icon ${isVirtual ? 'btn-virtual' : ''}`}
                          type="submit"
                        >
                          Submit
                          <span className="tb-icon">
                            {' '}
                            {isVirtual ? <i className="fal fa-vr-cardboard" /> : <Svg path="icon-arrow" />}
                          </span>
                        </button>
                      </form>
                    )}
                  </Formik>
                )}

                {!successfulSubmission && currentSecurityQuestion !== undefined && !contactUs && (
                  <>
                    <Formik
                      initialValues={
                        {
                          email: '',
                          password: '',
                          passwordConfirm: '',
                          securityAnswer: '',
                        } as ResetPasswordConfirmationFormValues
                      }
                      validationSchema={ResetPasswordConfirmationFormValidationSchema}
                      onSubmit={onSubmit}
                    >
                      {({ errors, values, touched, submitCount }) => (
                        <Form translate>
                          {currentSecurityQuestion !== undefined && (
                            <>
                              {formatSecurityQuestion(currentSecurityQuestion)}
                              <FormGroup>
                                <Field component={TextboxField} id="securityAnswer" placeholder="Security Answer" />
                              </FormGroup>
                              <FormGroup>
                                <Field component={TextboxField} id="email" placeholder="Email" autoComplete="nope" />
                              </FormGroup>
                              <FormGroup>
                                <Field
                                  type="password"
                                  component={TextboxField}
                                  id="password"
                                  placeholder="New Password"
                                />
                              </FormGroup>
                              <FormGroup>
                                <Field
                                  type="password"
                                  component={TextboxField}
                                  id="passwordConfirm"
                                  placeholder="Confirm New Password"
                                />
                              </FormGroup>
                            </>
                          )}

                          <button className={`btn btn-block btn-light btn-icon`} type="submit">
                            Submit
                            <span className="tb-icon">
                              <Svg path="icon-arrow" />
                            </span>
                          </button>
                        </Form>
                      )}
                    </Formik>
                  </>
                )}
                {successfulSubmission && (
                  <>
                    <p>Your password has been reset.</p>
                    <Link
                      to={`/&grp=${group}`}
                      className={`forgot-password-login-link btn btn-block btn-light btn-icon ${
                        isVirtual ? 'btn-virtual' : ''
                      }`}
                    >
                      Log in
                      <span className="tb-icon">
                        {isVirtual ? <i className="fal fa-vr-cardboard" /> : <Svg path="icon-arrow" />}
                      </span>
                    </Link>
                  </>
                )}

                {resetError && (
                  <p
                    className="error"
                    style={{ marginBottom: 0, marginTop: '1rem' }}
                    dangerouslySetInnerHTML={{
                      __html: resetError,
                    }}
                  ></p>
                )}

                {authError && (
                  <p
                    className="error"
                    style={{ marginBottom: 0, marginTop: '1rem' }}
                    dangerouslySetInnerHTML={{
                      __html: authError,
                    }}
                  ></p>
                )}

                {contactUs && (
                  <>
                    <ContactPhone siteGroup={group} />
                    <br />
                    <strong>
                      <ContactEmail siteGroup={group} className="contact-email" />
                    </strong>
                  </>
                )}

                <noscript>
                  <div className="alert alert-danger">
                    <h4 className="error" style={{ marginBottom: 0 }}>
                      Javascript is required for this site.
                    </h4>
                  </div>
                </noscript>

                <hr />

                <p>
                  New to <SiteName siteGroup={group} />?{' '}
                  {isVirtual ? (
                    <ExternalLink href={Config.applicationUrl + siteParam}>Open a virtual trading account</ExternalLink>
                  ) : (
                    <TradingBlockLink siteGroup={group} origin={group} to="OpenNewAccountUrl">
                      Open an account
                    </TradingBlockLink>
                  )}
                </p>
                {!isVirtual && (
                  <p>
                    <i className="fal fa-vr-cardboard" /> Want to test new ideas?{' '}
                    <ExternalLink href={Config.virtual.defaultRedirect + siteParam}>
                      Log in to your virtual trading account
                    </ExternalLink>
                  </p>
                )}
              </div>
              <div className="login-secondary">
                <img alt="" aria-hidden="true" src="/images/screen-desktop.png" />
              </div>
            </div>
          </div>
        </Section>
      </main>
      <footer className="footer">
        <div className="content">
          <FooterTeaser siteGroup={group} />
        </div>
      </footer>
    </div>
  );
}

export interface ResetPasswordConfirmationRenderProps {
  handleSubmit: (values: ResetPasswordConfirmationFormValues) => void;
}

/**
 * Fetch the user requirements info from the API and memorize it.
 * @returns {Promise<UserRequirementsInfo>}
 */
const fetchAPIPasswordRequirementsMemo = (): Promise<UserRequirementsInfo> => {
  let result: UserRequirementsInfo | undefined = undefined;
  let isFetching = false;

  return new Promise<UserRequirementsInfo>(async (resolve, reject) => {
    if (isFetching && result) {
      resolve(result);
    } else {
      isFetching = true;
      fetch(Config.dashboard.tradingApi + '/' + UserRequirementsUrl).then(res => {
        res.json().then(data => {
          result = data.Payload as UserRequirementsInfo;
          resolve(result);
        });
      });
    }
  });
};
/**
 * Creates a memoized version of the password requirements function. So we do not need to request it again and again.
 */
const fetchAPIPasswordRequirements = fetchAPIPasswordRequirementsMemo();

const ResetPasswordConfirmationFormValidationSchema = Yup.object().shape<ResetPasswordConfirmationFormValues>({
  password: Yup.string()
    .required('Password is required')
    .test('testPasswordRequirements', 'Password did not pass requirements', async function(value) {
      let requirements: UserRequirementsInfo = {
        // default values
        nameRequirements: {
          minLength: 6,
          maxLength: 30,
          minDigitCount: 0,
          minSpecialCount: 0,
          acceptableSpecialChars: '~!#$=+._',
          minLowerCount: 0,
          minUpperCount: 0,
        },
        passwordRequirements: {
          minCharAllowed: '!',
          maxCharAllowed: '~',
          minLowerCount: 1,
          minUpperCount: 1,
          minDigitCount: 1,
          minSpecialCount: 1,
          minLength: 8,
          maxLength: 0,
          preferredMinLength: 12,
        },
      };
      try {
        requirements = { ...requirements, ...(await fetchAPIPasswordRequirements) };
      } catch (e) {
        // return this.createError({
        //   message: 'Failed to fetch password requirements',
        // });
      }

      const passwordRequirements = requirements.passwordRequirements;
      const minLowerCountRegex = new RegExp(`[a-z]{${passwordRequirements.minLowerCount},}`);
      const minUpperCountRegex = new RegExp(`[A-Z]{${passwordRequirements.minLowerCount},}`);
      const minDigitCountRegex = new RegExp(`[0-9]{${passwordRequirements.minDigitCount},}`);
      const minSpecialCountRegex = new RegExp(`[^a-zA-Z0-9]{${passwordRequirements.minSpecialCount},}`);
      const allowedChars = new RegExp(
        `[${passwordRequirements.minCharAllowed}-${passwordRequirements.maxCharAllowed}]{${passwordRequirements.minLength},}`
      );

      const passwordRequirementsTest = Yup.string()
        .min(passwordRequirements.minLength, `Password must be at least ${passwordRequirements.minLength} characters.`)
        .max(
          passwordRequirements.maxLength === 0 ? 255 : passwordRequirements.maxLength,
          `Password must be at most ${passwordRequirements.maxLength} characters.`
        )
        .matches(
          minLowerCountRegex,
          `Password must contain at least ${passwordRequirements.minLowerCount} lowercase characters.`
        )
        .matches(
          minUpperCountRegex,
          `Password must contain at least ${passwordRequirements.minUpperCount} uppercase characters.`
        )
        .matches(minDigitCountRegex, `Password must contain at least ${passwordRequirements.minDigitCount} digits.`)
        .matches(
          minSpecialCountRegex,
          `Password must contain at least ${passwordRequirements.minSpecialCount} special characters.`
        )
        .matches(
          allowedChars,
          `Password must contain at least ${passwordRequirements.minLength} characters from the set ${passwordRequirements.minCharAllowed}-${passwordRequirements.maxCharAllowed}.`
        );

      try {
        passwordRequirementsTest.validateSync(value);
        return true;
      } catch (e) {
        if (e instanceof Yup.ValidationError) {
          return this.createError(e);
        }
        return false;
      }
    }),
  passwordConfirm: Yup.string()
    .required('Password Confirmation is required.')
    .oneOf([Yup.ref('password'), null], 'Confirmation Password Does Not Match'),

  email: Yup.string().required('Email is required.'),

  securityAnswer: Yup.string().required('Security Answer is required.'),
});
