import React, { useState, useContext, useEffect, useRef } from 'react';
import { Link, RouteComponentProps, useHistory } from 'react-router-dom';
import * as H from 'history';
import { Formik, Field } from 'formik';
import _ from 'lodash';
import './Home.css';
import {
  Section,
  FormGroup,
  TextboxField,
  TradingBlockLink,
  useAuthentication,
  Svg,
  FooterTeaser,
  SiteLogo,
  SiteName,
  ExternalLink,
  Header,
  SubHeader,
  useTracking,
} from '@tradingblock/components';
import { TryLogin, isString, GetUrlParams, NoPassEnabledURL, TryNoPassLogin, ApiFactory } from '@tradingblock/api';
import { toHashParams } from '../utils';
import { ApiServerConfigurationContext } from './ApiServerConfigurationContext';
import { ConfigType, VirtualTradingPaths, config as Config } from '../clientConfig';
import { BaseApiUrl, TradingBlockEnvironments, UserProfile } from '@tradingblock/types';
import jwt from 'jsonwebtoken';
import { NoPassAuthModal } from './NoPassAuthModal';
import ImageCanvas from './ImageCanvas';

interface LoginValues {
  username: string;
  password: string;
}
interface FormValues {
  signin: LoginValues;
}

const getEnvironment = (location: H.Location<any>) => {
  const params = GetUrlParams(location.search);
  const environment = params['environment'] || undefined;
  if (environment) {
    return environment;
  } else if (
    Config.environment === TradingBlockEnvironments.virtual ||
    VirtualTradingPaths.includes(location.pathname)
  ) {
    return 'virtual';
  } else {
    return 'dashboard';
  }
};

const getConfig = (env: any): ConfigType => {
  if (env === 'virtual') {
    return Config.virtual;
  }
  return Config.dashboard;
};

export const Login: React.FunctionComponent<RouteComponentProps> = ({ location }) => {
  const { defaultRedirectUrl } = useContext(ApiServerConfigurationContext);
  const [{ isAuthenticating }, { setIsAuthenticating }] = useAuthentication();
  const history = useHistory();

  const params = GetUrlParams(location.search);
  const env = getEnvironment(location);
  const isVirtual = env === 'virtual';
  const config = getConfig(env);
  const apiRootUrl = config.apiRoot || undefined;
  const tradingApiRootUrl = config.tradingApi || undefined;
  const noPassImageRef = useRef<HTMLDivElement>(null);

  const redirect = params['redirect'] || defaultRedirectUrl;
  const next = params['next'];
  const grp = Config.siteGrp;
  const clientType = params['type'];
  const siteParam = grp ? `?grp=${grp}` : '';
  let group = grp === 'mb' ? 'mb' : 'tb';

  const [authError, setAuthError] = useState<string>();
  const [pageLoadedWithJs, setPageLoadedWithJs] = useState<boolean>();
  const [username, setUsername] = useState<string | undefined>();
  const [noPassEnabled, setNoPassEnabled] = useState<boolean>(false);
  const [Base64Image, setBase64Image] = useState<string | undefined>();
  const [requestInProgress, setRequestInProgress] = useState(false);
  const [noPassError, setNoPassError] = useState<string | undefined>();

  // function to check if the user has enabled NoPass login for their account
  // if they have, we will show the NoPass login button
  // if they haven't, we will hide the NoPass login button
  // const checkNoPass = async () => {
  //   if (username && username !== undefined) {
  //     const url = NoPassEnabledURL(username);
  //     const response = await fetch(`${tradingApiRootUrl}/${url}`);
  //     const data = await response.json();

  //     if (data && data.Payload === true) {
  //       setNoPassEnabled(true);
  //     } else {
  //       setNoPassEnabled(false);
  //     }
  //   }
  // };

  // useEffect(() => {
  //   checkNoPass();
  // }, [username]);

  // const { initTracking } = useTracking(config.gaUaTrackingCode, config.ga4TrackingCode);

  useEffect(() => {
    // if javascript is disabled, this useEffect won't be fired, and the login form won't be rendered
    setPageLoadedWithJs(true);
    // initTracking();
  }, []);

  // handle validate
  const onValidate = (values: FormValues) => {
    if (!values.signin.username) {
      return { username: 'Username is required' };
    }
    if (username && !values.signin.password) {
      return { password: 'Password is required' };
    }
    return {};
  };

  // handle submit
  const onSubmit = (values: FormValues) => {
    if (isVirtual) {
      trySignin(values);
    } else if (!username) {
      setUsername(values.signin.username);
    } else {
      trySignin(values);
    }
  };

  // const tryNoPassSignin = async (values: FormValues) => {
  //   const loginValues = { ...values.signin, clientType: clientType };
  //   const result = await TryNoPassLogin(
  //     loginValues,
  //     tradingApiRootUrl || '',
  //     apiRootUrl || '',
  //     setBase64Image,
  //     setRequestInProgress,
  //     setNoPassError
  //   );
  //   if (result) {
  //     const nextParam = next ? '?next=' + next : '';
  //     const redirectUrl = `${redirect || ''}${nextParam}${toHashParams({
  //       id_token: result.token,
  //       grp: group,
  //     })}`;
  //     window.location.href = redirectUrl;
  //   }
  // };

  const trySignin = async (values: FormValues) => {
    if (!values.signin.username || !values.signin.password) {
      setAuthError('Username/Password not set');
      return;
    } else if (!redirect) {
      setAuthError('Redirect url not set');
      return;
    }
    setAuthError(undefined);
    setIsAuthenticating(true);
    const loginValues = { ...values.signin, clientType: clientType };
    await TryLogin(loginValues, { subroot: apiRootUrl, subdirectory: Config.subdirectory }).then(
      async ({ token, success, reason, getTokenResponseCode }) => {
        if (token) {
          // Decode JWT.
          const publicKey = await (await fetch(
            `${Config.subdirectory ? Config.subdirectory + '/' : ''}${BaseApiUrl}/publickey`
          )).json();
          const { token: accessToken, accountId, pending } = jwt.verify(token, publicKey.key) as {
            token?: string;
            accountId?: number;
            pending?: boolean;
          };

          // We are routing to the market data questionnaire page in the case of 400 or 401
          // response codes from the get token endpoint.
          if (getTokenResponseCode === 401 || getTokenResponseCode === 400) {
            let userProfile: UserProfile | null = null;

            if (accessToken) {
              const api = ApiFactory(config.tradingApi, accessToken as string);

              userProfile = await api.user.profile().then(res => res.payload);
            }

            // Only route to the market data questionnaire page in the case where the user does not have any pending entitlement requests.
            if (
              userProfile &&
              userProfile.entitlement &&
              !userProfile.entitlement.isPendingMarketDataEntitlementPolicyUpdatePresent
            ) {
              history.push(
                `/market-data-questionnaire?accessToken=${accessToken}&accountId=${accountId}&token=${token}&redirect=${redirect}&next=${next}`
              );
              return;
            }
          }

          // Send request for if the user has signed all documents
          if (!pending && !isVirtual) {
            const agreements = await (await fetch(
              `${
                Config.environment === TradingBlockEnvironments.dashboard
                  ? Config.dashboard.tradingApi
                  : Config.virtual.tradingApi
              }/Accounts/GetDocumentStatus?accountId=${accountId}`,
              {
                headers: {
                  authorization: `${accessToken}`,
                },
              }
            )).json();
            if (Object.values(agreements.Payload).includes(false)) {
              window.location.href = `/updated-agreements?token=${token}&accessToken=${accessToken}&accountId=${accountId}`;
              return;
            }
          }
          const nextParam = next ? '?next=' + next : '';
          const redirectUrl = `${redirect || ''}${nextParam}${toHashParams({
            id_token: token,
            grp: group,
          })}`;
          window.location.href = redirectUrl;
        } else {
          const error: string = isString(reason) ? reason : _.get(reason, 'message', JSON.stringify(reason));
          console.debug('skipping redirect', success, token);
          setAuthError(error);
        }

        setIsAuthenticating(false);
      }
    );
  };

  useEffect(() => {
    if (Base64Image) {
      const imageBase64 = `data:image/png;base64,${Base64Image}`;
      if (noPassImageRef.current) {
        noPassImageRef.current.innerHTML = `<img src="${imageBase64}" />`;
      }

      // set a timer to clear the base64 image after 30 seconds
      const timer = setTimeout(() => {
        setBase64Image(undefined);
        setRequestInProgress(false);
        setNoPassError('Request timed out. Please try again.');
      }, 30000);

      // clear the timer if the component is unmounted
      return () => clearTimeout(timer);
    }
    return;
  }, [Base64Image]);

  useEffect(() => {
    // clear NoPassError after 5 seconds
    if (noPassError) {
      const timer = setTimeout(() => {
        setNoPassError(undefined);
      }, 5000);

      return () => clearTimeout(timer);
    }
    return;
  }, [noPassError]);

  const href = location.pathname + location.search;

  const [showNoPassAuthModal, setShowNoPassAuthModal] = useState(false);

  const toggleNoPassAuthModal = () => {
    setShowNoPassAuthModal(!showNoPassAuthModal);
  };

  useEffect(() => {
    if (Base64Image) {
      toggleNoPassAuthModal();
    }
  }, [Base64Image]);

  // if the user closes the NoPassAuthModal, we need to clear the Base64Image
  // and set the requestInProgress to false
  useEffect(() => {
    if (!showNoPassAuthModal) {
      setBase64Image(undefined);
      setRequestInProgress(false);
    }
  }, [showNoPassAuthModal]);

  // if nopass error is set, we need to close the NoPassAuthModal
  useEffect(() => {
    if (noPassError && showNoPassAuthModal) {
      toggleNoPassAuthModal();
    }
  }, [noPassError]);

  const LoadingComponent = () => {
    return (
      <div className="container-fluid" style={{ display: 'flex', justifyContent: 'center' }}>
        <div className="spinner">
          <i className="fa fa-spinner fa-spin" />
        </div>
        <p className="loading-text">
          <strong>Authorizing...</strong>
        </p>
      </div>
    );
  };

  return (
    <>
      <div className={isVirtual ? 'env-virtual' : ''}>
        <Header>
          <SubHeader>
            <SiteLogo siteGroup={group} />
            <nav className="nav-utilities">
              <ul>
                <li>
                  {isVirtual ? (
                    <ExternalLink
                      href={
                        (Config.siteGrp === 'mb'
                          ? 'https://legacy.moneyblock.com/Account/SecuritiesLogin.aspx'
                          : Config.goToLiveUrl) + siteParam
                      }
                    >
                      Go to live trading account
                    </ExternalLink>
                  ) : (
                    <TradingBlockLink siteGroup={group} origin={group} to="LegacySiteHomeUrl">
                      &uarr; Go to <SiteName siteGroup={group} /> Legacy
                    </TradingBlockLink>
                  )}
                </li>
                <li>
                  <TradingBlockLink siteGroup={group} origin={group} to="CustomerServiceUrl">
                    Contact us
                  </TradingBlockLink>
                </li>
              </ul>
            </nav>
          </SubHeader>
        </Header>
        <main className="login">
          <Section>
            <div className="content">
              <div className="login-container">
                <div className="login-primary">
                  <h1>
                    <i aria-hidden="true" className={`fal ${isVirtual ? 'fa-vr-cardboard' : 'fa-lock-alt'}`} />{' '}
                    {isVirtual ? 'Virtual Log in' : username ? `${username}` : 'Log in'}
                  </h1>
                  {isVirtual && (
                    <>
                      <p>
                        Welcome to Virtual Trading. If you do not have a new Virtual account,{' '}
                        <ExternalLink href={Config.applicationUrl + siteParam}>
                          create a virtual trading account
                        </ExternalLink>
                      </p>
                      <p>NOTE: Your live account login is not compatible with Virtual Trading.</p>
                    </>
                  )}
                  {pageLoadedWithJs && (
                    <Formik
                      validate={onValidate}
                      // validateOnBlur={true}
                      validateOnChange={true}
                      initialValues={{ signin: { username: '', password: '' } }}
                      onSubmit={onSubmit}
                    >
                      {({ errors, touched, submitCount, handleSubmit, values }) => (
                        <form onSubmit={handleSubmit}>
                          {!username && (
                            <FormGroup>
                              <Field
                                component={TextboxField}
                                id="signin.username"
                                placeholder="Username"
                                autoFocus={true}
                              />
                              {errors['username'] && (touched['username'] || !!submitCount) && (
                                <span className="error">{errors['username']}</span>
                              )}
                            </FormGroup>
                          )}
                          {(isVirtual || username) && (
                            <FormGroup>
                              <Field
                                type="password"
                                component={TextboxField}
                                id="signin.password"
                                placeholder="Password"
                                autoFocus={!isVirtual}
                                allowUnmask
                              />
                              {errors['password'] && (touched['password'] || !!submitCount) && (
                                <span className="error">{errors['password']}</span>
                              )}
                            </FormGroup>
                          )}
                          {/* {noPassEnabled && username && (
                            <button
                              className="btn btn-block btn-icon"
                              style={{
                                backgroundColor: '#001236',
                                color: '#FFF',
                              }}
                              type="button"
                              onClick={() => tryNoPassSignin(values)}
                            >
                              No-Password Login
                              <span className="tb-icon">
                                <i className="fas fa-mobile-alt" />
                              </span>
                            </button>
                          )} */}
                          <button
                            className={`btn btn-block btn-light btn-icon ${isVirtual ? 'btn-virtual' : ''}`}
                            disabled={!!isAuthenticating}
                            type="submit"
                          >
                            {isVirtual || username ? 'Log in' : 'Next'}
                            <span className="tb-icon">
                              {isVirtual ? <i className="fal fa-vr-cardboard" /> : <Svg path="icon-arrow" />}
                            </span>
                          </button>
                        </form>
                      )}
                    </Formik>
                  )}

                  {authError && !isAuthenticating && (
                    <p
                      className="error"
                      style={{ marginBottom: 0, marginTop: '1rem' }}
                      dangerouslySetInnerHTML={{
                        __html: authError,
                      }}
                    ></p>
                  )}
                  {/* {noPassError && (
                    <h4 className="error" style={{ marginBottom: 0, marginTop: '1rem' }}>
                      {noPassError}
                    </h4>
                  )} */}

                  <noscript>
                    <div className="alert alert-danger">
                      <h4 className="error" style={{ marginBottom: 0 }}>
                        Javascript is required for this site.
                      </h4>
                    </div>
                  </noscript>

                  <hr />
                  {username && (
                    <>
                      <p>
                        Want to use a different account?{' '}
                        <button
                          style={{ color: 'black' }}
                          onClick={() => {
                            setUsername(undefined);
                            // set the formik values to empty
                          }}
                        >
                          Go back
                        </button>
                      </p>
                    </>
                  )}
                  <p>
                    Trouble logging in? <Link to={`/forgot-password?grp=${grp}`}>Reset password</Link> or{' '}
                    <TradingBlockLink siteGroup={group} origin={group} to="CustomerServiceUrl">
                      contact us
                    </TradingBlockLink>
                  </p>
                  <p>
                    New to <SiteName siteGroup={group} />?{' '}
                    {isVirtual ? (
                      <ExternalLink href={Config.applicationUrl + siteParam}>
                        Open a virtual trading account
                      </ExternalLink>
                    ) : (
                      <TradingBlockLink siteGroup={group} origin={group} to="OpenNewAccountUrl" config={config}>
                        Open an account
                      </TradingBlockLink>
                    )}
                  </p>
                  {!isVirtual && (
                    <p>
                      <i className="fal fa-vr-cardboard" /> Want to test new ideas?{' '}
                      <ExternalLink href={Config.virtual.defaultRedirect + siteParam}>
                        Log in to your virtual trading account
                      </ExternalLink>
                    </p>
                  )}
                </div>
                <div className="login-secondary">
                  <>
                    <img alt="" aria-hidden="true" src="/images/screen-desktop.png" />
                    {requestInProgress && !showNoPassAuthModal && <LoadingComponent />}
                  </>
                </div>
              </div>
            </div>
          </Section>
        </main>
        <footer className="footer">
          <div className="content">
            <FooterTeaser siteGroup={group} />
          </div>
        </footer>
      </div>
      {/* <NoPassAuthModal
        show={showNoPassAuthModal}
        onClose={() => toggleNoPassAuthModal()}
        onContinue={() => toggleNoPassAuthModal}
      >
        <div
          className="container-fluid fadeIn"
          style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}
        >
          <div className="row" style={{ alignItems: 'center', width: '75%', height: '75%', marginBottom: '10px' }}>
            <ImageCanvas base64Image={`data:image/png;base64,${Base64Image}`} />
          </div>
          <div className="row justify-content-center">
            <p style={{ textAlign: 'center', color: '#FFF' }}>
              <strong>
                A push notification is being sent to your registered mobile device. Please review it to accept or reject
                the request.
              </strong>
            </p>
          </div>
        </div>
      </NoPassAuthModal> */}
    </>
  );
};
