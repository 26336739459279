import React,{ FunctionComponent, useEffect, useState, useMemo, useRef, Fragment }  from 'react';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import useSWR from 'swr';
import useSWRMutation from 'swr/mutation';
import { Formik, Form, Field, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import { Button } from 'react-bootstrap';
import _ from 'lodash';

import {
    FooterTeaser,
    FormGroup,
    Header,
    Section,
    SiteLogo,
    SiteName,
    SubHeader,
    TradingBlockLink,
    StepperField,
    ExternalLink,
    SelectDropdownField,
    TextboxField,
    Loading,
    Tooltip,
} from '@tradingblock/components';
import { config as Config } from '../clientConfig';
import {
    AllStates,
    ApiResponse,
    Country,
    EmploymentType,
    EmploymentTypes,
    Groups,
    MarketDataQuestionnaireEmployerAddress,
    MarketDataQuestionnaireFormValues,
    MarketDateQuestionnaireYesNoQuestions,
    ResponseCodes,
    getGroupedCountryOptions,
} from '@tradingblock/types';
import { ApiFactory, GetDocumentStatusUrl, GetUrlParams, GetUserMarketDataEntitlementsUrl, SubmitMktDataUserProfileReviewUrl } from '@tradingblock/api';
import { toHashParams } from '../utils';

const defaultInitialMarketDataQuestionnaireFormValues: MarketDataQuestionnaireFormValues = {
    realtimeRequested: null,
    employmentType: null,
    employer: null,
    employerAddress: {
        address1: null,
        address2: null,
        city: null,
        country: null,
        postalCode: null,
        state: null,
    },
    occupation: null,
    employmentFunctions: null,
    jobPosition: null,
    questions: {
        finraRelated: null,
        industryRelated: null,
        stakeHolder: null,
        govOfficial: null,
        foreignShellBank: null,
        nonPersonalUse: null,
        businessUse: null,
        secCftcRegistration: null,
        associationRegistration: null,
        jobFunctions: null,
        investmentProfessional: null,
        assetManager: null,
        capitalTrading: null,
        corporationTrading: null,
        profitSharing: null,
        benefitsReceived: null,
    },
}

const firstSetOfBooleanFormFields = {
    finraRelated: 'Are you currently affiliated with or employed by a member of a stock exchange, FINRA, the SEC or any other securities association or regulatory body, foreign or domestic?',
    industryRelated: 'Do you have an affiliation with or work for a securities firm, bank, trust, or insurance company?',
    stakeHolder: 'Are you a director, 10% shareholder, or policy-making officer of a publicly owned company?',
    govOfficial: 'Are you a senior foreign government official, a family member of such an official or a close associate of such an official?',
    foreignShellBank: 'Are you a Foreign Shell Bank?',
    nonPersonalUse: 'Do you use Market Data for any non-personal use?',
}

const secondSetOfBooleanFormFields = {
    businessUse: 'Do you receive Market Data for your business or any other entity?',
    secCftcRegistration: 'Are you currently registered or qualified with the SEC or the CFTC?',
    associationRegistration: 'Are you currently registered or qualified with any securities agency, any securities exchange, association or regulatory body, or any commodities or futures contract market, association or regulatory body, in the United States or elsewhere?',
    jobFunctions: 'Whether you are located within or outside of the United States, do you perform any functions that are similar to those that require an individual to register or qualify with the SEC, the CFTC, any other securities agency or regulatory body, any securities exchange or association, or any commodities or futures contract market, association or regulatory body?',
    investmentProfessional: 'Are you engaged to provide investment advice to any individual or entity?',
    assetManager: 'Are you engaged as an asset manager?',
    capitalTrading: 'Do you use the capital of any other individual or entity in the conduct of your trading?',
    corporationTrading: 'Do you conduct trading for the benefit of a corporation, partnership, or other entity?',
    profitSharing: 'Have you entered into any agreement to share the profit of your trading activities or receive compensation for your trading activities?',
    benefitsReceived: 'Are you receiving office space, and equipment or other benefits in exchange for your trading or work as a financial consultant to any person, firm or business entity?',
}

const sixthQuestionKey = Object.keys(firstSetOfBooleanFormFields)[5];

const createYesNoStepperFieldData = (isDisabled: boolean): { label: string; value: boolean; disabled: boolean; }[] => (
    [{ label: 'Yes', value: true, disabled: isDisabled }, { label: 'No', value: false, disabled: isDisabled }]
)

export const MarketDataQuestionnaire: FunctionComponent<RouteComponentProps> = ({ location }) => {
    const siteGrp = Config.siteGrp;
    const group = siteGrp === Groups.mb ? Groups.mb: Groups.tb;
    const urlParams = GetUrlParams(location.search);
    const token: string = urlParams['token'];
    const accessToken: string = urlParams['accessToken'];
    const accountId: number = Number.parseInt(urlParams['accountId']);
    const redirect: string = urlParams['redirect'];
    const next: string = urlParams['next'];

    const [showSecondSetOfBooleanFields, setShowSecondSetOfBooleanFields] = useState<boolean | null>(null);
    const [isProfessionalUser, setIsProfessionalUser] = useState<boolean | null>(null);
    const [isRequestingRealTimeQuotes, setIsRequestingRealTimeQuotes] = useState<boolean | null>(null);
    const [submitMktDataUserProfileReviewErrMsg, setSubmitMktDataUserProfileReviewErrMsg] = useState<string | null>(null);
    const [pageLoadedWithJs, setPageLoadedWithJs] = useState<boolean>(false);
    const [triggerGetDocumentStatusRetryCount, setTriggerGetDocumentStatusRetryCount] = useState<number>(0);
    const [hasClickedOnExchangeDataAgreementLink, setHasClickedOnExchangeDataAgreementLink] = useState<boolean | null>(null);
    const [isEmployed, setIsEmployed] = useState<boolean | null>(null);
    const [isCountryUs, setIsCountryUs] = useState<boolean | null>(null);
    const [initialMarketDataQuestionnaireFormValues, setInitialMarketDataQuestionnaireFormValues] = useState<MarketDataQuestionnaireFormValues>(defaultInitialMarketDataQuestionnaireFormValues);
    const [previousFormDataSet, setPreviousFormDataSet] = useState<boolean>(false);
    const [changesMadeToForm, setChangesMadeToForm] = useState<boolean>(false);
    const [potentiallyPro, setPotentiallyPro] = useState<boolean | null>(null);

    const prevIsRequestingRealTimeQuotes = useRef<boolean | null>(null);

    const history = useHistory();

    const api = useMemo(() => ApiFactory(Config.dashboard.tradingApi, accessToken), [accessToken]);

    const {
        data: getDocumentStatusData,
        trigger: triggerGetDocumentStatus,
        error: getDocumentStatusError
    } = useSWRMutation(
        `${Config.dashboard.tradingApi}/${GetDocumentStatusUrl}`,
        (_url: string, { arg }: { arg: { accountId: number } }) => api.accounts.getDocumentStatus(arg.accountId),
    );
    const {
        trigger: triggerSubmitMktDataUserProfileReview,
    } = useSWRMutation(
        `${Config.dashboard.tradingApi}/${SubmitMktDataUserProfileReviewUrl}`,
        (_url: string, { arg }: { arg: { marketDataFormValues: MarketDataQuestionnaireFormValues }}) => api.entitlements.submitMktDataUserProfileReview(arg.marketDataFormValues),
    );
    const { isLoading: isLoadingUserMarketDataEntitlementsData, data: getUserMarketDataEntitlementsResponse, error: errorGettingUserMarketDataEntitlementsData } = useSWR(
        accessToken ? `${Config.dashboard.tradingApi}/${GetUserMarketDataEntitlementsUrl}` : null,
        () => api.entitlements.getUserMarketDataEntitlements(),
        { revalidateOnFocus: false, shouldRetryOnError: false },
    );

    useEffect(() => {
        setPageLoadedWithJs(true);
    }, []);

    useEffect(() => {
        if (getUserMarketDataEntitlementsResponse && !errorGettingUserMarketDataEntitlementsData && getUserMarketDataEntitlementsResponse.payload) {
            if (getUserMarketDataEntitlementsResponse.responseCode === 0) {
                const { potentiallyPro, ...userMarketDataEntitlementsDataExcludingPotentiallyPro } = getUserMarketDataEntitlementsResponse.payload;

                let nonPersonalUse: boolean | null = null;

                if (potentiallyPro) {
                    nonPersonalUse = true;
                } else {
                    if (userMarketDataEntitlementsDataExcludingPotentiallyPro.questions.nonPersonalUse === false || userMarketDataEntitlementsDataExcludingPotentiallyPro.questions.nonPersonalUse === true) {
                        nonPersonalUse = userMarketDataEntitlementsDataExcludingPotentiallyPro.questions.nonPersonalUse;
                    } else {
                        nonPersonalUse = defaultInitialMarketDataQuestionnaireFormValues.questions.nonPersonalUse;
                    }
                }

                setInitialMarketDataQuestionnaireFormValues({
                    ...defaultInitialMarketDataQuestionnaireFormValues,
                    ...userMarketDataEntitlementsDataExcludingPotentiallyPro,
                    questions: Object.keys(userMarketDataEntitlementsDataExcludingPotentiallyPro.questions).length === 0 ? defaultInitialMarketDataQuestionnaireFormValues.questions : {
                        ...defaultInitialMarketDataQuestionnaireFormValues.questions,
                        ...userMarketDataEntitlementsDataExcludingPotentiallyPro.questions,
                        nonPersonalUse,
                    },
                    // In the case where the user has an employment type of student, unemployed, or retired set previously clear out any employment information the backend is sending.
                    ...(userMarketDataEntitlementsDataExcludingPotentiallyPro.employmentType !== EmploymentType.Employed && {
                        employerAddress: defaultInitialMarketDataQuestionnaireFormValues.employerAddress,
                        employer: defaultInitialMarketDataQuestionnaireFormValues.employer,
                        jobPosition: defaultInitialMarketDataQuestionnaireFormValues.jobPosition,
                        employmentFunctions: defaultInitialMarketDataQuestionnaireFormValues.employmentFunctions,
                        occupation: defaultInitialMarketDataQuestionnaireFormValues.occupation,
                    }),
                });
                setPreviousFormDataSet(true);
                setPotentiallyPro(potentiallyPro);
            }
        }
    }, [getUserMarketDataEntitlementsResponse, errorGettingUserMarketDataEntitlementsData]);

    useEffect(() => {
        prevIsRequestingRealTimeQuotes.current = isRequestingRealTimeQuotes;
    }, [isRequestingRealTimeQuotes]);

    // If the account ID is set in state and the document status data is not set or the get document status endpoint returned an error
    // call the get document status endpoint.
    useEffect(() => {
        if (
            accountId &&
            triggerGetDocumentStatusRetryCount <= 5 &&
            (!getDocumentStatusData || getDocumentStatusData.responseCode !== 0 || getDocumentStatusError)) {
            // If the request to get the user's document status data responds with a code of 200 we need to redirect the user to log in again.
            if (getDocumentStatusData && getDocumentStatusData.responseCode === 200) {
                history.push('/');
                return;
            }

            triggerGetDocumentStatus({ accountId });
            setTriggerGetDocumentStatusRetryCount(triggerGetDocumentStatusRetryCount + 1);
        }
    }, [accountId, getDocumentStatusData, getDocumentStatusError]);

    const onSubmit = async (values: MarketDataQuestionnaireFormValues, { setSubmitting }: FormikHelpers<MarketDataQuestionnaireFormValues>) => {
        if (!hasClickedOnExchangeDataAgreementLink && isRequestingRealTimeQuotes) {
            setHasClickedOnExchangeDataAgreementLink(false);
            setSubmitting(false);
            return;
        }

        try {
            const cleanedDataForPostRequest = {
                ...values,
                employerAddress: !isEmployed ? null : {
                    ...values.employerAddress as MarketDataQuestionnaireEmployerAddress,
                    address1: values.employerAddress && values.employerAddress.address1 && values.employerAddress.address1.trim(),
                    address2: values.employerAddress && values.employerAddress.address2 && values.employerAddress.address2.trim(),
                    city: values.employerAddress && values.employerAddress.city && values.employerAddress.city.trim(),
                    postalCode: values.employerAddress && values.employerAddress.postalCode && values.employerAddress.postalCode.trim(),
                    state: values.employerAddress && values.employerAddress.state && values.employerAddress.state.trim(),
                },
                employer: values.employer && values.employer.trim(),
                occupation: values.occupation && values.occupation.trim(),
                employmentFunctions: values.employmentFunctions && values.employmentFunctions.trim(),
                jobPosition: values.jobPosition && values.jobPosition.trim(),
            };

            const { responseCode: submitMktDataUserProfileReviewResponseCode } = await triggerSubmitMktDataUserProfileReview({
                marketDataFormValues: cleanedDataForPostRequest,
            }) as ApiResponse<{}>;
            
            if (submitMktDataUserProfileReviewResponseCode !== 0) {
                // If the access token is expired (10hrs if not used and 24hrs if used) we need to route the user to the login page to get a new access token.
                if (submitMktDataUserProfileReviewResponseCode === 200) {
                    history.push('/');
                    return;
                }

                if (ResponseCodes[submitMktDataUserProfileReviewResponseCode]) {
                    setSubmitMktDataUserProfileReviewErrMsg(ResponseCodes[submitMktDataUserProfileReviewResponseCode].description as string);
                } else {
                    setSubmitMktDataUserProfileReviewErrMsg('Something went wrong submitting the form. Please try submitting again.');
                }

                setSubmitting(false);
                return;
            }

            if (submitMktDataUserProfileReviewResponseCode === 0) {
                if (getDocumentStatusData && getDocumentStatusData.payload && getDocumentStatusData.responseCode === 0 && typeof getDocumentStatusData.payload === 'object') {
                    if (Object.values(getDocumentStatusData.payload).includes(false)) {
                        history.push(`/updated-agreements?token=${token}&accessToken=${accessToken}&accountId=${accountId}`);
                        return;
                    }
                }

                const nextParam = next ? `?next=${next}` : '';
                const redirectUrl = `${redirect || ''}${nextParam}${toHashParams({ id_token: token, grp: group })}`

                window.location.href = redirectUrl;
            }
        } catch {
            setSubmitMktDataUserProfileReviewErrMsg('Something went wrong submitting the form. Please try submitting again.');
            setSubmitting(false);
        }
    }

    const determineIfProfessionalUser = (values: MarketDataQuestionnaireFormValues) =>
        setIsProfessionalUser(
            !!Object.keys(firstSetOfBooleanFormFields).find((booleanFormField) => values.questions[booleanFormField])
        );

    const shouldShowSecondSetOfBooleanFields = (values: MarketDataQuestionnaireFormValues) =>
        setShowSecondSetOfBooleanFields(values.questions[sixthQuestionKey]);

    const determineIfRequestingRealTimeQuotes = (values: MarketDataQuestionnaireFormValues) =>
        setIsRequestingRealTimeQuotes(values.realtimeRequested);

    const formFieldValidationSchema = Yup.string().nullable().trim().required('This field is required.');

    const handleAgreementClick = () => {
        setHasClickedOnExchangeDataAgreementLink(true);
    }

    return (
        <>
            <Header>
                <SubHeader>
                    <SiteLogo siteGroup={group} />
                    <nav className="nav-utilities">
                        <ul>
                            <li>
                                <TradingBlockLink siteGroup={group} origin={group} to="LegacySiteHomeUrl">
                                    &uarr; Go to <SiteName siteGroup={group} /> Legacy
                                </TradingBlockLink>
                            </li>
                            <li>
                                <TradingBlockLink siteGroup={group} origin={group} to="CustomerServiceUrl">
                                    Contact us
                                </TradingBlockLink>
                            </li>
                        </ul>
                    </nav>
                </SubHeader>
            </Header>
            <main className="market-data-questionnaire">
                <Section>
                    <div className="content">
                        <h1>Market Data User Profile Review</h1>

                        <p>
                            Please answer the questions below to verify your status as a professional or non-professional user of
                            NYSE, AMEX, Nasdaq and OPRA market data. Please note:
                        </p>
                        <ul>
                            <li>Delayed market data is provided at no cost to all users.</li>
                            <li>Real-time market data is provided at no cost to all non-professional users.</li>
                            <li>Real-time market data is provided at cost to all professional users.</li>
                        </ul>

                        {pageLoadedWithJs && (
                            <>
                                {isLoadingUserMarketDataEntitlementsData && (
                                    <div className="loading-container">
                                        <Loading />
                                    </div>
                                )}

                                {/* The additional checks are here to ensure that if there is a good response from the GET endpoint that we wait to make sure that */}
                                {/* the data is included in the initial form data. We are also checking for any error or empty states in which case we will let the form data fall back to the default state. */}
                                {!isLoadingUserMarketDataEntitlementsData && (
                                    (getUserMarketDataEntitlementsResponse && getUserMarketDataEntitlementsResponse.responseCode === 0 && previousFormDataSet) ||
                                    !getUserMarketDataEntitlementsResponse ||
                                    getUserMarketDataEntitlementsResponse.responseCode !== 0 ||
                                    errorGettingUserMarketDataEntitlementsData
                                ) &&
                                    <Formik
                                        validateOnBlur
                                        validateOnChange
                                        validationSchema={Yup.object<MarketDataQuestionnaireFormValues>().shape({
                                            realtimeRequested: formFieldValidationSchema,
                                            questions: Yup.object<MarketDateQuestionnaireYesNoQuestions>().shape({
                                                finraRelated: formFieldValidationSchema,
                                                industryRelated: formFieldValidationSchema,
                                                stakeHolder: formFieldValidationSchema,
                                                govOfficial: formFieldValidationSchema,
                                                foreignShellBank: formFieldValidationSchema,
                                                nonPersonalUse: formFieldValidationSchema,
                                                ...(showSecondSetOfBooleanFields && {
                                                    businessUse: formFieldValidationSchema,
                                                    associationRegistration: formFieldValidationSchema,
                                                    jobFunctions: formFieldValidationSchema,
                                                    investmentProfessional: formFieldValidationSchema,
                                                    secCftcRegistration: formFieldValidationSchema,
                                                    capitalTrading: formFieldValidationSchema,
                                                    corporationTrading: formFieldValidationSchema,
                                                    profitSharing: formFieldValidationSchema,
                                                    benefitsReceived: formFieldValidationSchema,
                                                    assetManager: formFieldValidationSchema,
                                                }),
                                            }),
                                            ...(isRequestingRealTimeQuotes && {
                                                employmentType: formFieldValidationSchema,
                                                ...(isEmployed && {
                                                    employerAddress: Yup.object<MarketDataQuestionnaireEmployerAddress>().shape({
                                                        address1: formFieldValidationSchema,
                                                        city: formFieldValidationSchema,
                                                        country: formFieldValidationSchema,
                                                        ...((isCountryUs || isCountryUs === null) && {
                                                            postalCode: formFieldValidationSchema,
                                                            state: formFieldValidationSchema,
                                                        }),
                                                    }),
                                                    employmentFunctions: formFieldValidationSchema,
                                                    jobPosition: formFieldValidationSchema,
                                                    occupation: formFieldValidationSchema,
                                                    employer: formFieldValidationSchema,
                                                }),
                                            })
                                        })}
                                        initialValues={initialMarketDataQuestionnaireFormValues}
                                        onSubmit={onSubmit}
                                    >
                                        {({ values, isSubmitting, resetForm }) => {
                                            // This section will cleanup the form fields if they are hidden and set back to the null value.
                                            // Or if the field is visible the value of the field will be set to the form value or the initial form data state value.
                                            useMemo(() => {
                                                const getBooleanFieldValue = (booleanFormFieldKey: string): boolean | null => {
                                                    if (values.questions[booleanFormFieldKey] === false || values.questions[booleanFormFieldKey] === true) {
                                                        return values.questions[booleanFormFieldKey];
                                                    }
    
                                                    if (initialMarketDataQuestionnaireFormValues.questions[booleanFormFieldKey] === false || initialMarketDataQuestionnaireFormValues.questions[booleanFormFieldKey] === true) {
                                                        return initialMarketDataQuestionnaireFormValues.questions[booleanFormFieldKey];
                                                    }
    
                                                    return null;
                                                }
                                                resetForm({
                                                    values: {
                                                        ...values,
                                                        questions: {
                                                            ...values.questions,
                                                            ...(showSecondSetOfBooleanFields !== null && Object.keys(secondSetOfBooleanFormFields).reduce((accumulator, booleanFormFieldKey,) => ({
                                                                ...accumulator,
                                                                [booleanFormFieldKey]: showSecondSetOfBooleanFields ? getBooleanFieldValue(booleanFormFieldKey) : defaultInitialMarketDataQuestionnaireFormValues.questions[booleanFormFieldKey],
                                                            }), {})),
                                                        },
                                                        ...(isRequestingRealTimeQuotes !== null && { employmentType: isRequestingRealTimeQuotes ? (values.employmentType || initialMarketDataQuestionnaireFormValues.employmentType) : defaultInitialMarketDataQuestionnaireFormValues.employmentType }),
                                                        ...(isEmployed !== null && {
                                                            employer: isEmployed ? (values.employer || initialMarketDataQuestionnaireFormValues.employer) : defaultInitialMarketDataQuestionnaireFormValues.employer,
                                                            occupation: isEmployed ? (values.occupation || initialMarketDataQuestionnaireFormValues.occupation) : defaultInitialMarketDataQuestionnaireFormValues.occupation,
                                                            employmentFunctions: isEmployed ? (values.employmentFunctions || initialMarketDataQuestionnaireFormValues.employmentFunctions) : defaultInitialMarketDataQuestionnaireFormValues.employmentFunctions,
                                                            jobPosition: isEmployed ? (values.jobPosition || initialMarketDataQuestionnaireFormValues.jobPosition) : defaultInitialMarketDataQuestionnaireFormValues.jobPosition,
                                                            employerAddress: isEmployed ? ((values.employerAddress && !!Object.keys(values.employerAddress).find((employerAddressKey) => values.employerAddress && !!values.employerAddress[employerAddressKey]) && values.employerAddress) || initialMarketDataQuestionnaireFormValues.employerAddress) : defaultInitialMarketDataQuestionnaireFormValues.employerAddress,
                                                        })
                                                    }
                                                });
                                            }, [showSecondSetOfBooleanFields, isEmployed, isRequestingRealTimeQuotes, initialMarketDataQuestionnaireFormValues]);
                                            useMemo(() =>
                                                shouldShowSecondSetOfBooleanFields(values),
                                                [values.questions[sixthQuestionKey]]
                                            );
                                            useMemo(() => 
                                                determineIfProfessionalUser(values),
                                                [
                                                    values.questions.finraRelated,
                                                    values.questions.industryRelated,
                                                    values.questions.stakeHolder,
                                                    values.questions.govOfficial,
                                                    values.questions.foreignShellBank,
                                                    values.questions.nonPersonalUse,
                                                ]
                                            );
                                            useMemo(() =>
                                                determineIfRequestingRealTimeQuotes(values),
                                                [values.realtimeRequested]
                                            );
                                            useMemo(() => 
                                                setIsEmployed(values.employmentType === EmploymentType.Employed),
                                                [values.employmentType]
                                            );
                                            useMemo(() => {
                                                // This will reset if they have viewed the exchange data agreement if the isRequestingRealTimeQuotes state changes.
                                                if ((!isRequestingRealTimeQuotes && prevIsRequestingRealTimeQuotes) ||
                                                    (isRequestingRealTimeQuotes && !prevIsRequestingRealTimeQuotes)
                                                ) {
                                                    setHasClickedOnExchangeDataAgreementLink(null);
                                                }
                                            }, [isRequestingRealTimeQuotes]);
                                            useMemo(() => {
                                                if (!values.employerAddress || (values.employerAddress && !values.employerAddress.country)) {
                                                    setIsCountryUs(null);
                                                } else if (values.employerAddress && values.employerAddress.country === Country.UnitedStatesOfAmerica) {
                                                    setIsCountryUs(true);
                                                } else if (values.employerAddress && values.employerAddress.country !== Country.UnitedStatesOfAmerica) {
                                                    setIsCountryUs(false);
                                                }
                                            }, [values.employerAddress && values.employerAddress.country]);
                                            // Whenever there is a change in the form data do a check to see
                                            // if the data is different than the initial form data.
                                            // This will dictate the form button text.
                                            useMemo(() => {
                                                const getBooleanFieldValue = (booleanFormFieldKey: string): boolean | null => {
                                                    if (initialMarketDataQuestionnaireFormValues.questions[booleanFormFieldKey] === true || initialMarketDataQuestionnaireFormValues.questions[booleanFormFieldKey] === false) {
                                                        return initialMarketDataQuestionnaireFormValues.questions[booleanFormFieldKey];
                                                    }

                                                    return null;
                                                }
                                                const modifiedInitialMarketDataQuestionnaireFormValues = {
                                                    ...initialMarketDataQuestionnaireFormValues,
                                                    questions: {
                                                        ...initialMarketDataQuestionnaireFormValues.questions,
                                                        ...(showSecondSetOfBooleanFields !== null && Object.keys(secondSetOfBooleanFormFields).reduce((accumulator, booleanFormFieldKey,) => ({
                                                            ...accumulator,
                                                            [booleanFormFieldKey]: showSecondSetOfBooleanFields ? getBooleanFieldValue(booleanFormFieldKey) : defaultInitialMarketDataQuestionnaireFormValues.questions[booleanFormFieldKey],
                                                        }), {})),
                                                    },
                                                    ...(isRequestingRealTimeQuotes !== null && { employmentType: isRequestingRealTimeQuotes ? initialMarketDataQuestionnaireFormValues.employmentType : defaultInitialMarketDataQuestionnaireFormValues.employmentType }),
                                                    ...(isEmployed !== null && {
                                                        employer: isEmployed ? initialMarketDataQuestionnaireFormValues.employer : defaultInitialMarketDataQuestionnaireFormValues.employer,
                                                        occupation: isEmployed ? initialMarketDataQuestionnaireFormValues.occupation : defaultInitialMarketDataQuestionnaireFormValues.occupation,
                                                        employmentFunctions: isEmployed ? initialMarketDataQuestionnaireFormValues.employmentFunctions : defaultInitialMarketDataQuestionnaireFormValues.employmentFunctions,
                                                        jobPosition: isEmployed ? initialMarketDataQuestionnaireFormValues.jobPosition : defaultInitialMarketDataQuestionnaireFormValues.jobPosition,
                                                        employerAddress: isEmployed ? 
                                                            (initialMarketDataQuestionnaireFormValues.employerAddress &&
                                                                !!Object.keys(initialMarketDataQuestionnaireFormValues.employerAddress)
                                                                    .find((employerAddressKey) =>
                                                                        initialMarketDataQuestionnaireFormValues.employerAddress &&
                                                                        !!initialMarketDataQuestionnaireFormValues.employerAddress[employerAddressKey]))
                                                            && initialMarketDataQuestionnaireFormValues.employerAddress :
                                                            defaultInitialMarketDataQuestionnaireFormValues.employerAddress,
                                                    })
                                                }

                                                if (!_.isEqual(modifiedInitialMarketDataQuestionnaireFormValues, values) && !changesMadeToForm) {
                                                    setChangesMadeToForm(true);
                                                } else if (_.isEqual(modifiedInitialMarketDataQuestionnaireFormValues, values) && changesMadeToForm) {
                                                    setChangesMadeToForm(false);
                                                }
                                            }, [values, initialMarketDataQuestionnaireFormValues]);

                                            return (
                                                <Form translate={undefined}>
                                                    {Object.keys(firstSetOfBooleanFormFields).map((booleanFormFieldKey) => (
                                                        <Fragment key={booleanFormFieldKey}>
                                                            {
                                                                (booleanFormFieldKey !== sixthQuestionKey || (booleanFormFieldKey === sixthQuestionKey && !potentiallyPro)) &&
                                                                <FormGroup>
                                                                    <label htmlFor={`questions.${booleanFormFieldKey}`}>{firstSetOfBooleanFormFields[booleanFormFieldKey]}</label>
                                                                    <Field
                                                                        id={`questions.${booleanFormFieldKey}`}
                                                                        name={`questions.${booleanFormFieldKey}`}
                                                                        component={StepperField}
                                                                        options={createYesNoStepperFieldData(isSubmitting)}
                                                                    />
                                                                    </FormGroup>
                                                            }
                                                            {
                                                                booleanFormFieldKey === sixthQuestionKey && potentiallyPro &&
                                                                    <FormGroup>
                                                                        <Tooltip
                                                                            content={'It has been determined you are a potentially pro user. Because of this the answer to this question must be "Yes".'}
                                                                            id={booleanFormFieldKey}
                                                                        >
                                                                            <label htmlFor={`questions.${booleanFormFieldKey}`}>{firstSetOfBooleanFormFields[booleanFormFieldKey]}</label>
                                                                            <Field
                                                                                id={`questions.${booleanFormFieldKey}`}
                                                                                name={`questions.${booleanFormFieldKey}`}
                                                                                component={StepperField}
                                                                                options={createYesNoStepperFieldData(true)}
                                                                            />
                                                                        </Tooltip>
                                                                    </FormGroup>
                                                            }
                                                        </Fragment>
                                                    ))}

                                                    {showSecondSetOfBooleanFields && (
                                                        <>
                                                            {Object.keys(secondSetOfBooleanFormFields).map((booleanFormFieldKey) => (
                                                                <FormGroup key={booleanFormFieldKey}>
                                                                    <label htmlFor={booleanFormFieldKey}>{secondSetOfBooleanFormFields[booleanFormFieldKey]}</label>
                                                                    <Field
                                                                        id={`questions.${booleanFormFieldKey}`}
                                                                        name={`questions.${booleanFormFieldKey}`}
                                                                        component={StepperField}
                                                                        options={createYesNoStepperFieldData(isSubmitting)}
                                                                    />
                                                                </FormGroup>
                                                                ))}
                                                        </>
                                                    )}

                                                    <FormGroup className="quotes">
                                                        <label htmlFor={"realtimeRequested"}>Select whether you wish to receive Real-time or Delayed quotes:</label>
                                                        <Field
                                                            id="realtimeRequested"
                                                            name="realtimeRequested"
                                                            component={StepperField}
                                                            options={[{ label: 'Delayed quotes (Free to all users)', value: false, disabled: isSubmitting }, { label: 'Real-time quotes (Free to non-pro users)', value: true, disabled: isSubmitting }]}
                                                        />
                                                    </FormGroup>

                                                    {isProfessionalUser && isRequestingRealTimeQuotes && (
                                                        <div className="professional-user-fees">
                                                            <p className="professional-user-qualification">
                                                                Based on the preceding information you supplied as part of this account application, you or the entity applying for this brokerage account qualifies as a "Professional User" as defined by the NYSE, AMEX, NASDAQ and the US options exchanges.
                                                            </p>
                                                            <p>Exchanges define a "Professional User" as an individual or entity that meets any one of the following qualifications:</p>
                                                            <ol>
                                                                <li>An employee or a member of any stock exchange or of any business registered under any securities law or regulation; or</li>
                                                                <li>An individual or entity trading in the account as a paid agent for a third party; or</li>
                                                                <li>An individual or entity whose account is in the name of a corporation, partnership or sole proprietorship.</li>
                                                            </ol>
                                                            <p>
                                                                <span>Professional User Data Fees May Apply.</span> Professional Users must choose to receive real-time or 15-minute delayed market quotes. Delayed market quotes are provided free of charge. Professional Users selecting real-time market quotes will be charged the following monthly data exchange fees:
                                                            </p>
                                                            <ul>
                                                                <li>NYSE - $45.00</li>
                                                                <li>AMEX - $23.00</li>
                                                                <li>NASDAQ - $23.00</li>
                                                                <li>OPRA - $31.50</li>
                                                            </ul>
                                                            <p>
                                                                All fees and applicable taxes for real-time market quotes are charged in U.S. Dollars and are automatically deducted from your brokerage account each month and remitted to the exchange on your behalf. Fees are subject to change on a minimum of 60 days notice.
                                                            </p>
                                                        </div>
                                                    )}

                                                    {isRequestingRealTimeQuotes && (
                                                        <div className="employment-data-form-groups">
                                                            <h4>Employment Information</h4>
                                                            <FormGroup>
                                                                <Field
                                                                    component={SelectDropdownField}
                                                                    isSearchable={false}
                                                                    id="employmentType"
                                                                    name="employmentType"
                                                                    options={EmploymentTypes}
                                                                    label="Employment Status"
                                                                    disabled={isSubmitting}
                                                                />
                                                            </FormGroup>
                                                            {isEmployed && (
                                                                <>
                                                                    <FormGroup>
                                                                        <Field
                                                                            component={TextboxField}
                                                                            id="employer"
                                                                            name="employer"
                                                                            label="Employer Name"
                                                                            disabled={isSubmitting}
                                                                        />
                                                                    </FormGroup>
                                                                    <FormGroup>
                                                                        <Field
                                                                            component={TextboxField}
                                                                            id="jobPosition"
                                                                            name="jobPosition"
                                                                            label="Job Title"
                                                                            disabled={isSubmitting}
                                                                        />
                                                                    </FormGroup>
                                                                    <FormGroup>
                                                                        <Field
                                                                            component={TextboxField}
                                                                            id="occupation"
                                                                            name="occupation"
                                                                            label="Employment Occupation"
                                                                            disabled={isSubmitting}
                                                                        />
                                                                    </FormGroup>
                                                                    <FormGroup>
                                                                        <Field
                                                                            component={TextboxField}
                                                                            id="employmentFunctions"
                                                                            name="employmentFunctions"
                                                                            label="Employment Function"
                                                                            disabled={isSubmitting}
                                                                        />
                                                                    </FormGroup>

                                                                    <h5>Employer Address</h5>
                                                                    <FormGroup>
                                                                        <Field
                                                                            component={SelectDropdownField}
                                                                            name="employerAddress.country"
                                                                            id="employerAddress.country"
                                                                            label="Country"
                                                                            options={getGroupedCountryOptions()}
                                                                            optionsDefaultLabelId="country"
                                                                            disabled={isSubmitting}
                                                                        />
                                                                    </FormGroup>
                                                                    <FormGroup>
                                                                        <Field
                                                                            component={TextboxField}
                                                                            name="employerAddress.address1"
                                                                            id="employerAddress.address1"
                                                                            label="Address 1"
                                                                            disabled={isSubmitting}
                                                                        />
                                                                    </FormGroup>
                                                                    <FormGroup>
                                                                        <Field
                                                                            component={TextboxField}
                                                                            name="employerAddress.address2"
                                                                            id="employerAddress.address2"
                                                                            label="Address 2"
                                                                            disabled={isSubmitting}
                                                                        />
                                                                    </FormGroup>
                                                                    <FormGroup>
                                                                        <Field
                                                                            component={TextboxField}
                                                                            name="employerAddress.city"
                                                                            id="employerAddress.city"
                                                                            label="City"
                                                                            disabled={isSubmitting}
                                                                        />
                                                                    </FormGroup>
                                                                    {(isCountryUs || (values.employerAddress && values.employerAddress.country  === null)) && (
                                                                        <FormGroup>
                                                                            <Field
                                                                                component={SelectDropdownField}
                                                                                name="employerAddress.state"
                                                                                id="employerAddress.state"
                                                                                label="State"
                                                                                options={AllStates}
                                                                                optionsDefaultLabelId="state"
                                                                                disabled={isSubmitting}
                                                                            />
                                                                        </FormGroup>
                                                                    )}
                                                                    {!isCountryUs && values.employerAddress && values.employerAddress.country !== null && (
                                                                        <FormGroup>
                                                                            <Field
                                                                                component={TextboxField}
                                                                                name="employerAddress.state"
                                                                                id="employerAddress.state"
                                                                                label="Province"
                                                                                disabled={isSubmitting}
                                                                            />
                                                                        </FormGroup>
                                                                    )}
                                                                    <FormGroup>
                                                                        <Field
                                                                            component={TextboxField}
                                                                            name="employerAddress.postalCode"
                                                                            id="employerAddress.postalCode"
                                                                            label="Postal Code"
                                                                            disabled={isSubmitting}
                                                                        />
                                                                    </FormGroup>
                                                                </>
                                                            )}
                                                        </div>
                                                    )}

                                                    {isRequestingRealTimeQuotes && (
                                                        <div className="professional-user-agreements">
                                                            <p>
                                                                By selecting real-time quotes, you are required to review the Exchange Data Agreement listed below. Please click on the agreement title below to open the agreement in a new tab.
                                                            </p>
                                                            <p>
                                                                By submitting this form, you agree that you have received, read and understand the Exchange Data Agreement, and you agree to all the provisions in the document.
                                                            </p>
                                                            <ExternalLink href={`${Config.dashboard.tradingApi}/Documents/Agreement/ExchangeDataAgreement`} onClick={handleAgreementClick}>Exchange Data Agreement</ExternalLink>
                                                        </div>
                                                    )}

                                                    <p className="submit-warning">By submitting this form, you agree that all questions answered above and information provided are truthful and accurate.</p>

                                                    {submitMktDataUserProfileReviewErrMsg && <p className="error form-error-msg">{submitMktDataUserProfileReviewErrMsg}</p>}

                                                    {
                                                        hasClickedOnExchangeDataAgreementLink === false &&
                                                        isRequestingRealTimeQuotes &&
                                                        <p className="error form-error-msg">You must open and review the Electronic Data Agreement.</p>
                                                    }

                                                    <div className="submit-btn-wrapper">
                                                        <Button
                                                            variant="primary"
                                                            type="submit"
                                                            disabled={isSubmitting}
                                                        >
                                                            {changesMadeToForm && 'Confirm Changes'}
                                                            {!changesMadeToForm && (isSubmitting ? 'Submitting' : 'Submit')}
                                                        </Button>
                                                    </div>
                                                </Form>
                                            )
                                        }}
                                    </Formik>
                                }
                            </>
                        )}

                        <noscript>
                            <div className="alert alert-danger">
                                <h4 className="error" style={{ marginBottom: 0 }}>
                                    JavaScript is required for this site.
                                </h4>
                            </div>
                        </noscript>
                    </div>
                </Section>
            </main>
            <footer className="footer">
                <div className="content">
                    <FooterTeaser siteGroup={group} />
                </div>
            </footer>
        </>
    );
};
