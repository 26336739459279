import React, { useState, useEffect, useContext } from 'react';
import { Link, RouteComponentProps, useParams } from 'react-router-dom';
import * as H from 'history';
import {
  Section,
  TradingBlockLink,
  FooterTeaser,
  SiteLogo,
  SiteName,
  ExternalLink,
  Header,
  SubHeader,
  useTracking,
  Loading,
} from '@tradingblock/components';
import { GetUrlParams } from '@tradingblock/api';
import { ConfigType, VirtualTradingPaths, config as Config } from '../clientConfig';
import { TradingBlockEnvironments } from '@tradingblock/types';
import _ from 'lodash';
import { ApiServerConfigurationContext } from './ApiServerConfigurationContext';
const getEnvironment = (location: H.Location<any>) => {
  const params = GetUrlParams(location.search);
  const environment = params['environment'] || undefined;
  if (environment) {
    return environment;
  } else if (
    Config.environment === TradingBlockEnvironments.virtual ||
    VirtualTradingPaths.includes(location.pathname)
  ) {
    return 'virtual';
  } else {
    return 'dashboard';
  }
};

const getConfig = (env: any): ConfigType => {
  if (env === 'virtual') {
    return Config.virtual;
  }
  return Config.dashboard;
};

export const EmailVerification: React.FunctionComponent<RouteComponentProps> = ({ location }) => {
  const { defaultRedirectUrl } = useContext(ApiServerConfigurationContext);
  const params = GetUrlParams(location.search);
  const redirect = params['redirect'] || defaultRedirectUrl;
  const env = getEnvironment(location);
  const isVirtual = env === 'virtual';
  const config = getConfig(env);
  const apiRootUrl = config.authurl || undefined;
  const { token } = useParams();

  const grp = Config.siteGrp;
  const siteParam = grp ? `?grp=${grp}` : '';
  let group = grp === 'mb' ? 'mb' : 'tb';

  const [authError, setAuthError] = useState<string>();
  const [pageLoadedWithJs, setPageLoadedWithJs] = useState<boolean>();
  const [isEmailConfirmed, setIsEmailConfirmed] = useState<boolean>(false);
  const [isFetching, setIsFetching] = useState<boolean>(false);
  const [verificationAttempted, setVerificationAttempted] = useState<boolean>(false);

  // const { initTracking } = useTracking(config.gaUaTrackingCode, config.ga4TrackingCode);

  useEffect(() => {
    // if javascript is disabled, this useEffect won't be fired, and the login form won't be rendered
    setPageLoadedWithJs(true);
    // initTracking();
  }, []);

  /* 
    If token is provided in the url, we will attempt to verify the email address.
    If the token is valid, we will set isEmailConfirmed to true.
    If the token is invalid or if there is an error, we will set authError to the error message.
  */
  useEffect(() => {
    setAuthError(undefined);
    if (token) {
      setIsFetching(true);

      fetch(`${apiRootUrl}/User/CreateUserEmailConfirm?conf=${token}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
      }).then(async response => {
        if (response.status === 200) {
          const content = await response.json();
          if (content.ResponseCode === 0) {
            setIsEmailConfirmed(true);
            setAuthError('');
            setIsFetching(false);
          } else {
            setIsFetching(false);
            setAuthError(
              `Email verification failed: ${content.Errors[0]} If the problem persists, please contact support.`
            );
          }
        } else {
          setIsFetching(false);
          setAuthError(
            `Email verification failed: ${response.status} ${response.statusText} If the problem persists, please contact support.`
          );
        }
      });
      setVerificationAttempted(true);
    }
  }, [token]);

  const href = location.pathname + location.search;

  return (
    <div className={isVirtual ? 'env-virtual' : ''}>
      <Header>
        <SubHeader>
          <SiteLogo siteGroup={group} />
          <nav className="nav-utilities">
            <ul>
              <li>
                {isVirtual ? (
                  <ExternalLink href={Config.goToLiveUrl + siteParam}>Go to live trading account</ExternalLink>
                ) : (
                  <TradingBlockLink to="LegacySiteHomeUrl" siteGroup={group} origin={group}>
                    &uarr; Go to <SiteName siteGroup={group} /> Legacy
                  </TradingBlockLink>
                )}
              </li>
              <li>
                <TradingBlockLink to="CustomerServiceUrl" siteGroup={group} origin={group}>Contact us</TradingBlockLink>
              </li>
            </ul>
          </nav>
        </SubHeader>
      </Header>
      <main className="login">
        <Section>
          <div className="content">
            <div className="login-container">
              <div className="login-primary">
                <h1>
                  <i aria-hidden="true" className={`fal ${isVirtual ? 'fa-user-check' : 'fa-user-check'} mb-4`} />{' '}
                  {isVirtual ? 'Virtual Email Verification' : 'Email Verification'}
                </h1>
                {pageLoadedWithJs && (
                  <>
                    {isFetching && (
                      <div className="loading-container">
                        <Loading />
                      </div>
                    )}
                    {!isFetching && verificationAttempted && (
                      <>
                        {isEmailConfirmed && (
                          <div className="container-fluid" style={{ display: 'flex', alignItems: 'center' }}>
                            <div className="row" style={{ alignItems: 'center' }}>
                              <div className="col-3">
                                <i className="fas fa-check-circle fa-5x" />
                              </div>
                              <div className="col-9">
                                <strong>
                                  <p style={{ textAlign: 'center', color: 'var(--blue-dark)', fontStyle: 'italic' }}>
                                    Email verified successfully. Your account is now ready to use.
                                  </p>
                                  <Link to={`/?redirect=${redirect}/auth&next=/&grp=${group}`}>
                                    <button className="btn btn-primary">
                                      <i aria-hidden="true" className="fal fa-sign-in-alt" />{' '}
                                      {isVirtual ? 'Login to Virtual Trading Account' : 'Login to Trading Account'}
                                    </button>
                                  </Link>
                                </strong>
                              </div>
                            </div>
                          </div>
                        )}
                        {!isEmailConfirmed && (
                          <div className="container-fluid" style={{ display: 'flex', alignItems: 'center' }}>
                            <div className="row" style={{ alignItems: 'center' }}>
                              <div className="col-3">
                                <i className="fas fa-exclamation-triangle fa-4x error" />
                              </div>
                              <div className="col-9">
                                <strong>
                                  <span
                                    style={{ fontStyle: 'italic', color: 'var(--blue-dark)' }}
                                    dangerouslySetInnerHTML={{
                                      __html: authError
                                        ? authError
                                        : 'Email verification failed. Please try again. If the problem persists, please contact support.',
                                    }}
                                  ></span>
                                </strong>
                              </div>
                            </div>
                          </div>
                        )}
                      </>
                    )}
                  </>
                )}

                <noscript>
                  <div className="alert alert-danger">
                    <h4 className="error" style={{ marginBottom: 0 }}>
                      Javascript is required for this site.
                    </h4>
                  </div>
                </noscript>

                <hr />

                <p>
                  New to <SiteName siteGroup={group} />?{' '}
                  {isVirtual ? (
                    <ExternalLink href={Config.applicationUrl + siteParam}>Open a virtual trading account</ExternalLink>
                  ) : (
                    <TradingBlockLink to="OpenNewAccountUrl" siteGroup={group} origin={group}>Open an account</TradingBlockLink>
                  )}
                </p>
                {!isVirtual && (
                  <p>
                    <i className="fal fa-vr-cardboard" /> Want to test new ideas?{' '}
                    <ExternalLink href={Config.virtual.defaultRedirect + siteParam}>
                      Log in to your virtual trading account
                    </ExternalLink>
                  </p>
                )}
              </div>
              <div className="login-secondary">
                <img alt="" aria-hidden="true" src="/images/screen-desktop.png" />
              </div>
            </div>
          </div>
        </Section>
      </main>
      <footer className="footer">
        <div className="content">
          <FooterTeaser siteGroup={group} />
        </div>
      </footer>
    </div>
  );
};
