import React, { useEffect } from 'react';
import { Route, Switch } from 'react-router-dom';
import _ from 'lodash';
import { useAuthentication, LocalizeProviderWrapper, useWindowSizeContext } from '@tradingblock/components';
import { Login } from './Login';
import { ForgotPassword } from './ForgotPassword';
import { ApiServerConfigurationContext } from './ApiServerConfigurationContext';
import './App.css';
import ResetPasswordConfirmation from './ResetPasswordConfirmation';
import UpdatedAgreements from './UpdatedAgreements';
import { EmailVerification } from './EmailVerification';
import ReactGA from 'react-ga4';
import { config } from '../clientConfig';
import { TradingBlockEnvironments } from '@tradingblock/types';
import { MarketDataQuestionnaire } from './MarketDataQuestionnaire';

const TRACKING_ID = config.environment === TradingBlockEnvironments.dashboard ? config.dashboard.ga4TrackingCode : config.virtual.ga4TrackingCode;
if (TRACKING_ID) {
  ReactGA.initialize(TRACKING_ID);
}

const App = ({ defaultRedirect }: { defaultRedirect: string }) => {
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);
  
  return (
    <useAuthentication.Provider>
        <ApiServerConfigurationContext.Provider value={{ defaultRedirectUrl: defaultRedirect }}>
          <LocalizeProviderWrapper>
            <useWindowSizeContext.Provider>
              <Switch>
                <Route path="/email-verification/:token" component={EmailVerification} />
                <Route path="/forgot-password" component={ForgotPassword} />
                <Route path="/reset-password/:token" component={ResetPasswordConfirmation} />
                <Route path="/updated-agreements" component={UpdatedAgreements} />
                <Route path="/market-data-questionnaire" component={MarketDataQuestionnaire} />
                <Route path="/" component={Login} />
              </Switch>
            </useWindowSizeContext.Provider>
          </LocalizeProviderWrapper>
        </ApiServerConfigurationContext.Provider>
      </useAuthentication.Provider>
  );
};

export default App;
