import React from 'react';
import { hydrate } from 'react-dom';
const BrowserRouter = require('react-router-dom').BrowserRouter;
import App from './ui/App';
import { config } from './clientConfig';

hydrate(
  <BrowserRouter basename={config.subdirectory}>
    <App defaultRedirect={config.dashboard.defaultRedirect} />
  </BrowserRouter>,
  document.getElementById('root')
);

if (module.hot) {
  module.hot.accept();
}
