import { TradingBlockEnvironments } from '@tradingblock/types';

export const VirtualTradingPaths = ['/vt', '/virtual'];

export const config = {
  goToLiveUrl: process.env.TB_DASHBOARD_URL as string,
  applicationUrl: process.env.TB_APPLICATION_URL as string,
  environment: process.env.ACTIVE_ENV ? process.env.ACTIVE_ENV : TradingBlockEnvironments.dashboard,
  subdirectory: (process.env.TB_SUBDIRECTORY as string) ? (process.env.TB_SUBDIRECTORY as string) : '',
  siteGrp: (process.env.TB_SITE_GROUP as string) ? (process.env.TB_SITE_GROUP as string) : 'tb',
  dashboard: {
    tradingApi: process.env.TB_RESTHUB_URL as string,
    authurl: process.env.TB_RESTHUB_URL as string,
    defaultRedirect: process.env.TB_DASHBOARD_URL as string,
    environment: TradingBlockEnvironments.dashboard,
    gaUaTrackingCode: process.env.TB_GA_UA_TRACKING_CODE as string,
    ga4TrackingCode: process.env.TB_GA_4_TRACKING_CODE as string,
  },
  virtual: {
    tradingApi: process.env.TB_VT_RESTHUB_URL as string,
    authurl: process.env.TB_VT_RESTHUB_URL as string,
    defaultRedirect: process.env.TB_VT_DASHBOARD_URL as string,
    apiRoot: 'virtual',
    environment: TradingBlockEnvironments.virtual,
    gaUaTrackingCode: process.env.TB_VT_GA_UA_TRACKING_CODE as string,
    ga4TrackingCode: process.env.TB_VT_GA_4_TRACKING_CODE as string,
  },
};

export type ConfigType = typeof config.dashboard & {
  apiRoot?: string;
  environment: string;
};
