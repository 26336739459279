import React, { useState, useEffect } from 'react';
import { RouteComponentProps, Link } from 'react-router-dom';
import * as H from 'history';
import { Formik, Field } from 'formik';
import _ from 'lodash';
import './Home.css';
import {
  Section,
  FormGroup,
  TextboxField,
  TradingBlockLink,
  Svg,
  FooterTeaser,
  SiteLogo,
  SiteName,
  ExternalLink,
  Header,
  SubHeader,
  useTracking,
  Loading,
} from '@tradingblock/components';
import { GetUrlParams } from '@tradingblock/api';
import { ConfigType, VirtualTradingPaths, config as Config } from '../clientConfig';
import { TradingBlockEnvironments } from '@tradingblock/types';

interface FormValues {
  UserName: string;
  Email: string;
}

const getEnvironment = (location: H.Location<any>) => {
  const params = GetUrlParams(location.search);
  const environment = params['environment'] || undefined;
  if (environment) {
    return environment;
  } else if (
    Config.environment === TradingBlockEnvironments.virtual ||
    VirtualTradingPaths.includes(location.pathname)
  ) {
    return 'virtual';
  } else {
    return 'dashboard';
  }
};

const getConfig = (env: any): ConfigType => {
  if (env === 'virtual') {
    return Config.virtual;
  }
  return Config.dashboard;
};

export const ForgotPassword: React.FunctionComponent<RouteComponentProps> = ({ location }) => {
  const params = GetUrlParams(location.search);
  const env = getEnvironment(location);
  const isVirtual = env === 'virtual';
  const config = getConfig(env);
  const apiRootUrl = config.authurl || undefined;

  const grp = Config.siteGrp;
  const siteParam = grp ? `?grp=${grp}` : '';
  let group = grp === 'mb' ? 'mb' : 'tb';

  const [authError, setAuthError] = useState<string>();
  const [pageLoadedWithJs, setPageLoadedWithJs] = useState<boolean>();
  const [sentEmail, setSentEmail] = useState<boolean>(false);
  const [isFetching, setIsFetching] = useState<boolean>(false);

  // const { initTracking } = useTracking(config.gaUaTrackingCode, config.ga4TrackingCode);

  useEffect(() => {
    // if javascript is disabled, this useEffect won't be fired, and the login form won't be rendered
    setPageLoadedWithJs(true);
    // initTracking();
  }, []);

  // handle validate
  const onValidate = (values: FormValues) => {
    if (!values.UserName || !values.Email) {
      return { [`UserName`]: 'Username is required.', [`Email`]: 'Email is required.' };
    }
    if (values.Email && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.Email)) {
      return { [`Email`]: 'Invalid email address.' };
    }
    return {};
  };

  // handle submit
  const onSubmit = async (values: FormValues) => {
    setIsFetching(true);
    await fetch(`${apiRootUrl}//User/PasswordChangeInitiate?siteid=${group}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        UserName: values.UserName,
        Email: values.Email,
      }),
    })
      .then(async response => {
        if (response.status === 200) {
          setIsFetching(false);
          const content = await response.json();
          if (content.ResponseCode === 0) {
            setAuthError('');
            setIsFetching(false);
            setSentEmail(true);
          } else {
            setIsFetching(false);
            setSentEmail(true);
          }
        }
      })
      .catch(error => {
        setAuthError(error.toString());
        setIsFetching(false);
        setSentEmail(true);
      });
  };

  const href = location.pathname + location.search;
  return (
    <div className={isVirtual ? 'env-virtual' : ''}>
      <Header>
        <SubHeader>
          <SiteLogo siteGroup={group} />
          <nav className="nav-utilities">
            <ul>
              <li>
                {isVirtual ? (
                  <ExternalLink href={Config.goToLiveUrl + siteParam}>Go to live trading account</ExternalLink>
                ) : (
                  <TradingBlockLink siteGroup={group} origin={group} to="LegacySiteHomeUrl" config={config}>
                    &uarr; Go to <SiteName siteGroup={group} /> Legacy
                  </TradingBlockLink>
                )}
              </li>
              <li>
                <TradingBlockLink siteGroup={group} origin={group} to="CustomerServiceUrl">
                  Contact us
                </TradingBlockLink>
              </li>
            </ul>
          </nav>
        </SubHeader>
      </Header>
      <main className="login">
        <Section>
          <div className="content">
            <div className="login-container">
              <div className="login-primary">
                <h1>
                  <i aria-hidden="true" className={`fal ${isVirtual ? 'fa-vr-cardboard' : 'fa-lock-alt'}`} />{' '}
                  {isVirtual ? 'Virtual Forgot Password' : 'Forgot Password'}
                </h1>
                {isVirtual && (
                  <>
                    <p>
                      Welcome to Virtual Trading. If you do not have a new Virtual account,{' '}
                      <ExternalLink href={Config.applicationUrl + siteParam}>
                        create a virtual trading account
                      </ExternalLink>
                    </p>
                    <p>NOTE: Your live account login is not compatible with Virtual Trading.</p>
                  </>
                )}
                {isFetching && <Loading />}
                {pageLoadedWithJs && !sentEmail && !isFetching && (
                  <Formik
                    validate={onValidate}
                    validateOnChange={true}
                    initialValues={{
                      UserName: '',
                      Email: '',
                    }}
                    onSubmit={onSubmit}
                  >
                    {({ errors, touched, submitCount, handleSubmit }) => (
                      <form onSubmit={handleSubmit}>
                        <FormGroup>
                          <Field component={TextboxField} id="UserName" placeholder="Username" autoFocus={true} />
                        </FormGroup>
                        <FormGroup>
                          <Field component={TextboxField} id="Email" placeholder="Email" autoFocus={true} />
                        </FormGroup>
                        <button
                          className={`btn btn-block btn-light btn-icon ${isVirtual ? 'btn-virtual' : ''}`}
                          type="submit"
                        >
                          Submit
                          <span className="tb-icon">
                            {' '}
                            {isVirtual ? <i className="fal fa-vr-cardboard" /> : <Svg path="icon-arrow" />}
                          </span>
                        </button>
                      </form>
                    )}
                  </Formik>
                )}
                {sentEmail && (
                  <div>
                    <p>
                      If a username and email match, you should have received a reset email with instructions on how to
                      reset your password.
                    </p>
                  </div>
                )}

                {authError && (
                  <p
                    className="error"
                    style={{ marginBottom: 0, marginTop: '1rem' }}
                    dangerouslySetInnerHTML={{
                      __html: authError,
                    }}
                  ></p>
                )}

                <noscript>
                  <div className="alert alert-danger">
                    <h4 className="error" style={{ marginBottom: 0 }}>
                      Javascript is required for this site.
                    </h4>
                  </div>
                </noscript>

                <hr />

                <p>
                  Remember your password? <Link to="/">Log in</Link>
                </p>

                <p>
                  New to <SiteName siteGroup={group} />?{' '}
                  {isVirtual ? (
                    <ExternalLink href={Config.applicationUrl + siteParam}>Open a virtual trading account</ExternalLink>
                  ) : (
                    <TradingBlockLink to="OpenNewAccountUrl" siteGroup={group} origin={group} config={config}>
                      Open an account
                    </TradingBlockLink>
                  )}
                </p>
                {!isVirtual && (
                  <p>
                    <i className="fal fa-vr-cardboard" /> Want to test new ideas?{' '}
                    <ExternalLink href={Config.virtual.defaultRedirect + siteParam}>
                      Log in to your virtual trading account
                    </ExternalLink>
                  </p>
                )}
              </div>
              <div className="login-secondary">
                <img alt="" aria-hidden="true" src="/images/screen-desktop.png" />
              </div>
            </div>
          </div>
        </Section>
      </main>
      <footer className="footer">
        <div className="content">
          <FooterTeaser siteGroup={group} />
        </div>
      </footer>
    </div>
  );
};
