import React, { useEffect, useMemo, useState } from 'react';
import { CheckboxField, ExternalLink, FooterTeaser, FormGroup, Header, Section, SiteLogo, SiteName, SubHeader, TradingBlockLink } from '@tradingblock/components';
import { config as Config, VirtualTradingPaths } from '../clientConfig';
import { TradingBlockEnvironments } from '@tradingblock/types';
import { GetUrlParams } from '@tradingblock/api';
import * as H from 'history';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import { toHashParams } from '../utils';

const getEnvironment = (location: H.Location<any>) => {
  const params = GetUrlParams(location.search);
  const environment = params['environment'] || undefined;
  if (environment) {
    return environment;
  } else if (
    Config.environment === TradingBlockEnvironments.virtual ||
    VirtualTradingPaths.includes(location.pathname)
  ) {
    return 'virtual';
  } else {
    return 'dashboard';
  }
};

export default function UpdatedAgreements({ location }) {
  const env = getEnvironment(location);
  const isVirtual = env === 'virtual';
  const params = GetUrlParams(location.search);
  const grp = Config.siteGrp;
  const token = params['token'];
  const accountId = params['accountId'];
  const accessToken = params['accessToken'];
  const siteParam = grp ? `?grp=${grp}` : '';
  let group = grp === 'mb' ? 'mb' : 'tb';
  const href = location.pathname + location.search;
  const [agreements, setAgreements] = useState<Record<string, boolean>>({});
  const [accountInfo, setAccountInfo] = useState<any>();
  const [accounts, setAccounts] = useState([])

  useEffect(() => {
    (async function () {
      try {
        const agreements = await (await fetch(`${Config.environment === TradingBlockEnvironments.dashboard ? Config.dashboard.tradingApi : Config.virtual.tradingApi}/Accounts/GetDocumentStatus?accountId=${accountId}`, {
          headers: {
            'authorization': `${accessToken}`,
          }
        })).json();
        const accounts = await (await fetch(`${Config.environment === TradingBlockEnvironments.dashboard ? Config.dashboard.tradingApi : Config.virtual.tradingApi}/Accounts/GetAccounts`, {
          headers: {
            'authorization': `${accessToken}`,
          }
        })).json();
        if (!Object.values(agreements.Payload).includes(false)) {
          window.location.href = '/';
        }
        setAccounts(accounts.Payload.map(a => a.AccountId));
        setAgreements(agreements.Payload);
        setAccountInfo({
          token: accessToken,
          accountId,
        });
      } catch (e) {
        console.error(e);
        window.location.href = '/'
      }
    }
    )()
  }, [])

  const agreementsListForm = useMemo(() => {
    const agreementsNeedSign = Object.keys(agreements).filter(agreementKey => !agreements[agreementKey]);
    if (agreementsNeedSign.length === 0) {
      return <>Preparing Agreements <i className="fas fa-circle-notch fa-spin valid" /></>
    }
    const validation = Yup.object().shape({
      consentToAll: Yup.boolean().oneOf([true], 'You must agree to all updated agreements.')
    });
    return (<Formik
      initialValues={{
        consentToAll: false,
      }}
      validationSchema={validation}
      onSubmit={(_, formikHelpers) => {
        formikHelpers.setSubmitting(true);
        accounts.forEach(async accountId => {
          fetch(`${Config.dashboard.tradingApi}/Accounts/UpdateDocumentStatus?accountId=${accountId}`, {
            method: "PUT",
            headers: {
              'authorization': `${accessToken}`,
              'content-type': 'application/json'
            },
            body: JSON.stringify(agreementsNeedSign.reduce((acc, key) => {
              acc[key] = true;
              return acc;
            }, {}))
          }).then((data) => {
            data.json().then((data) => {
              if (data['ResponseCode'] === 0) {
                window.location.href = `${Config.goToLiveUrl}/auth${toHashParams({
                  id_token: token,
                  grp: grp,
                })}`;
              } else {
                formikHelpers.setSubmitting(false);
                formikHelpers.setErrors({
                  consentToAll: 'There was an error signing your agreements. Please try again.',
                });
              }
            });
          });
        })
      }}>
      {/** TODO: Better style this with classes and handle translations */}
      {({ values, errors, touched, submitCount, handleSubmit, isSubmitting }) => (
        <Form translate={undefined} onSubmit={handleSubmit}>
          <div className='mb-4'>
            TradingBlock has modified the following agreement(s) governing your account. Please review each, and, if satisfactory, accept the agreement(s) as requested below.
            If you have any questions regarding these modifications, please contact Customer Service at 1-800-494-0451 or <a href="mailto:service@tradingblock.com?subject=TradingBlock Customer Service" >service@tradingblock.com.</a>
            <br /><br />You have {agreementsNeedSign.length} modified agreement(s) to accept.
          </div>
          <table style={{
            width: 'auto',
            borderWidth: 1,
            borderColor: 'white',
            borderStyle: 'solid',
          }} className='table'>
            <thead>
              <tr>
                <th style={{
                  color: 'white',
                  fontWeight: 'bold',
                  fontSize: 16
                }}>Status</th>
                <th style={{
                  color: 'white',
                  fontWeight: 'bold',
                  fontSize: 16
                }}>Agreement</th>
              </tr>
            </thead>
            <tbody>
              {agreementsNeedSign.map(agreementKey => {
                const capitalized = (agreementKey.charAt(0).toUpperCase() + agreementKey.slice(1)).match(/[A-Z][a-z]+/g)
                const title = capitalized ? capitalized.join(' ') : capitalized;
                return (
                  <tr>
                    <td style={{
                      color: 'white',
                      fontSize: 16
                    }}>{title}</td>
                    <td><a style={{
                      fontSize: 16
                    }} target='_blank' href={`${Config.dashboard.tradingApi}/Documents/Agreement/${agreementKey}`}>{title} Agreement</a></td>
                  </tr>
                )
              })}
            </tbody>
          </table>
          <FormGroup>
            <Field
              component={CheckboxField}
              id={`consentToAll`}
              label={`I consent to all agreements for my account(s).`}
            />
            {errors['consentToAll'] && (touched['consentToAll'] || !!submitCount) && (
              <span className="error">{errors['consentToAll']}</span>
            )}
          </FormGroup>
          <button
            className={`btn btn-block btn-light btn-icon`}
            style={{
              width: 'auto',
            }}
            type="submit"
            disabled={!values['consentToAll'] && !isSubmitting}
          >
            Continue To Dashboard
          </button>
        </Form>
      )}
    </Formik>)
  }, [agreements, accounts, accountInfo])

  return (
    <div className={isVirtual ? 'env-virtual' : ''}>
      <Header>
        <SubHeader>
          <SiteLogo siteGroup={grp} />
          <nav className="nav-utilities">
            <ul>
              <li>
                {isVirtual ? (
                  <ExternalLink href={Config.goToLiveUrl + siteParam}>Go to live trading account</ExternalLink>
                ) : (
                  <TradingBlockLink siteGroup={group} origin={group} to="LegacySiteHomeUrl">
                    &uarr; Go to <SiteName siteGroup={grp} /> Legacy
                  </TradingBlockLink>
                )}
              </li>
              <li>
                <TradingBlockLink siteGroup={group} origin={group} to="CustomerServiceUrl">Contact us</TradingBlockLink>
              </li>
            </ul>
          </nav>
        </SubHeader>
      </Header>
      <main className="login">
        <Section>
          <div className="content">
            <div className="login-container">
              <div>
                <noscript>
                  <div className="alert alert-danger">
                    <h4 className="error" style={{ marginBottom: 0 }}>
                      Javascript is required for this site.
                    </h4>
                  </div>
                </noscript>
                <h1>
                  Agreement Updates
                </h1>
                <hr />
                {agreementsListForm}
              </div>
              <div className="login-secondary">
                <img alt="" aria-hidden="true" src="/images/screen-desktop.png" />
              </div>
            </div>
          </div>
        </Section>
      </main>
      <footer className="footer">
        <div className="content">
          <FooterTeaser siteGroup={grp} />
        </div>
      </footer>
    </div>
  );
}